import axios from 'axios'

const apiBase = process.env.NEXT_PUBLIC_API_HOST
const SIMPLE_TOKEN = 'l09WUjRI1y@88ml5tmvmeAywk!CuRhls1RGgBS94rfW43'

const sendDataToCrisp = async (email, userData) => {
  try {
    let data
    if (userData.userId) {
      data = createSendingData(userData)
    } else {
      data = userData
    }
    return await axios.post(`${apiBase}/crisp/update-contact`, { email, data })
  } catch (e) {
    console.error(e)
  }
}

const sendDataToKlaviyo = async (email, userData) => {
  try {
    let data
    if (userData.userId) {
      data = createSendingData(userData)
    } else {
      data = userData
    }
    return await axios.post(`${apiBase}/klaviyo/update-contact`, {
      email,
      data
    })
  } catch (e) {
    console.error(e)
  }
}

const changeEmailsInKlaviyo = async ({ oldEmail, newEmail }) => {
  try {
    return await axios.post(`${apiBase}/klaviyo/updateEmail`, {
      oldEmail,
      newEmail,
      token: SIMPLE_TOKEN
    })
  } catch (e) {
    console.error(e)
  }
}

const createSendingData = (userData) => {
  let installedWebsite = userData.installWebsite ?? ''
  let platform = userData.platform?.name ?? 'generic'
  let currentPlan = userData.platform?.currentPlan ?? 'Free'
  if (userData.wordpress) {
    if (Array.isArray(userData.wordpress?.sites)) {
      installedWebsite = userData.wordpress.sites[0].siteUrl ?? ''
    }
  }

  return {
    app_name: 'promolayer',
    full_name: userData.fullName ?? userData.email,
    plan_name: currentPlan.toLowerCase(),
    installed_website: installedWebsite,
    language: userData.preferredLanguage,
    install_platform: platform.toLowerCase(),
    user_id: userData.userId,
    sign_up_date: Math.floor(
      new Date(userData.createdAt ?? Date.now()).getTime() / 1000
    ),
    uninstall: 0,
    shopify_embedded:
      platform === 'shopify' && userData.platform.appEmbed ? 1 : 0
  }
}

export { sendDataToCrisp, sendDataToKlaviyo, changeEmailsInKlaviyo }
