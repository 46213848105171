import { useEffect, useState } from 'react'

const getVersion = async () => {
  try {
    const response = await fetch('/api/versionChecker')
    const json = await response.json()
    const serverVersion = json.version
    return serverVersion
  } catch (e) {
    console.log(e)
  }
}
export const useVersionDetector = () => {
  const [versionUpdated, setVersionUpdated] = useState(false)

  useEffect(() => {
    setInterval(() => {
      getVersion()
        .then((version) => {
          if (version !== process.env.NEXT_PUBLIC_APP_VERSION) {
            setVersionUpdated(true)
          }
        })
        .catch(console.log)
    }, 5 * 60 * 1000) // 5 minutes
  }, [])
  return { versionUpdated }
}
